

















































































































































import RelatedLinks from '@/components/RelatedLinks.vue'
import VSelect from '@/components/form/VSelect.vue'

import axios, { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { useState } from '@u3u/vue-hooks'
import {
  defineComponent,
  computed,
  ref,
  Ref,
  onBeforeMount,
} from '@vue/composition-api'
import { getApiUrl } from '@/inc/app.config'
import { Btn, Listing } from '@/inc/types'

interface ContentAg {
  dateAg: number
  place: string
  title: string
  headline: string
  htmltext: string
  button: Btn
  files: Listing<Btn>
}

interface ResponseArchives {
  content: {
    archives: ContentArchives
  }
  totalPosts: number
}
type ContentArchives = Listing<Btn>

const updateArchives = async (
  archives: Ref<ContentArchives | undefined>,
  totalArchives: Ref<number>,
  year?: string
): Promise<void> => {
  const url = `${getApiUrl()}/generalmeeting/archives${
    year ? `?year=${year}` : ''
  }`
  const res = (await axios.get(url)) as AxiosResponse<ResponseArchives>

  // Hack to move from general meeting to myresa/ag
  res.data.content.archives.items = res.data.content.archives.items.map(i => {
    i.url = i.url.replace('generalmeeting', 'myresa/ag')

    return i
  })
  archives.value = res.data.content.archives

  if (!year) {
    totalArchives.value = res.data.totalPosts
  }
}

export default defineComponent({
  name: 'ag',
  components: {
    RelatedLinks,
    VSelect,
  },
  props: {},
  setup() {
    const year = ref<string>()
    const filterKey = ref(0)
    const { resource } = useState(['resource'])
    const ag: ContentAg = resource.value.content.latest
      ? resource.value.content.latest.content
      : resource.value.content
    // Hack to move from general meeting to myresa/ag
    if (ag.button) {
      ag.button.url = ag.button.url.replace('generalmeeting', 'myresa/ag/')
    }
    const archives = ref<ContentArchives>()
    const totalArchives = ref(0)
    const formatDateAg = (d: number) =>
      dayjs(parseInt(d.toString(), 10)).format('DD.MM.YYYY')

    const chrome = useState(['chrome'])
    const stringsShared = chrome.chrome.value.data.i18n

    onBeforeMount(() => {
      updateArchives(archives, totalArchives)
    })

    const filterOptions = computed(() => {
      if (totalArchives.value < 0 || !resource.value.content.available) {
        return []
      }

      return resource.value.content.available
        .sort((a: string, b: string) => parseInt(b, 10) - parseInt(a, 10))
        .map(option => ({ label: option, value: option }))
    })

    const onChange = (year: string) => {
      updateArchives(archives, totalArchives, year)
    }

    const resetFilter = () => {
      year.value = undefined
      filterKey.value += 1
      updateArchives(archives, totalArchives)
    }

    return {
      ag,
      content: resource.value.content,
      formatDateAg,
      archives,
      year,
      filterKey,
      filterOptions,
      onChange,
      resetFilter,
      stringsShared,
    }
  },
})
